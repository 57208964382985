import React from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import BandcampPlayer from 'react-bandcamp'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const BlockEmbed = (props) => {
	const { content, type, soundcloud_url, bandcamp_album_id } = props;

	return (
		<Container
			type={type}
		>
			{type == 'soundcloud' && soundcloud_url && (
				<Player
					url={soundcloud_url}
				/>
			)}

			{type == 'bandcamp' && bandcamp_album_id && (
				<BandcampPlayer 
					album={bandcamp_album_id} 
					width={'100%'}
					height={'120px'}
					artwork={'small'}
				/>
			)}

		</Container>
	)
}

const Container = styled.div`
    display: flex;
	justify-content: flex-end;

	${props => {
		if (props.type == 'bandcamp') return css`
			max-width: 700px;
			width: 100%;
			align-self: center;
		`
	}}
`

const Player = styled(ReactPlayer)`
	width: 100% !important;
	height: 30vw !important;
	min-height: 320px;
	border-radius: 8px;
	overflow: hidden;
`


BlockEmbed.wrapper = css`

`

export default BlockEmbed
