// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';

import { updateQuantity } from '../../store/cart';
import { hoverState } from '../../styles/global';

class Spinner extends Component {

    componentDidMount = () => {
      
    }

    updateQuantity = (modifier) => {
        const { updateQuantity, cartItem } = this.props;
        // console.log(modifier, cartItem)

        updateQuantity({
            ...cartItem,
            quantity: modifier == '+' ? cartItem.quantity + 1 : cartItem.quantity - 1
        })
    }
    
    render() {
        return ( 
            <Wrapper>
                <Control
                    onClick={() => this.updateQuantity('+')}
                >
                    +
                </Control>

                <Control
                    onClick={() => this.updateQuantity('-')}
                >
                    –
                </Control>
            </Wrapper>
        )
    }
    
}

const Wrapper = styled.div`
    display: flex;
`

const Control = styled.div`
    color: white;
    ${hoverState};
    user-select: none;
    font-size: 20px;

    &:not(:last-child) {
        margin-right: 12px;
    }
    
`

const mapDispatchToProps = dispatch => ({
    updateQuantity(cartItem) {
        dispatch(updateQuantity(cartItem))
    }
})

export default connect(null, mapDispatchToProps)(Spinner);
