import React, { useMemo, useState } from 'react'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import { connect } from 'react-redux'
import { forEach } from 'lodash'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import Client from 'shopify-buy';
import humanize from 'humanize-graphql-response'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { rgba } from 'polished';
import { blue, red } from '../../styles/colors';

import { fetchCart, getCart, addToCart } from '../../store/cart'
import { updateSettings } from '../../store/settings'
import { useMount } from 'react-use'

// Fetch products from Shopify

const client = Client.buildClient({
	storefrontAccessToken: 'aef0f9b15ea83b0891154d98f7841ede',
	domain: 'sound-merch-com-au.myshopify.com',
});

const Releases = (props) => {
	const graphData = useStaticQuery(query)

	const products = useMemo(() => {
		const productsObject = humanize(graphData?.allShopifyCollection?.nodes[0]?.products)

		const productsArray = []

		forEach(productsObject, (product) => {
			productsArray.push(product)
		})

		return productsArray
	}, [graphData])

	useMount(() => {
		if (isClient()) {
			window.history.pushState(null, null, '/shop');
		}
	})

	const addToCart = (product, variant) => {
		const { addToCart, updateSettings } = props;

		let variantId = variant ? product.variants[variant].id : product.variants[0].id
		let parsedVariantId = variantId.split('Shopify__ProductVariant__')[1]

		const item = {
			title: product.title,
			id: parsedVariantId,
			quantity: 1,
			total: variant ? product.variants[variant].price : product.variants[0].price, 
		}

		addToCart(item)

		updateSettings({
			cartActive: true
		})
	}

	const renderVariantsDropdown = (product) => {
		if (!product?.variants?.length) return

		let hasAvailableOption = false

		const options = product.variants.map((variant, i) => {
			if (variant.availableForSale) hasAvailableOption = true;

			return (
				<option
					value={i}
					disabled={!variant.availableForSale}
				>
					{!variant.availableForSale && 'Sold Out – '}
					{variant.title}
				</option>
			)
		})


		if (hasAvailableOption) {
			return (
				<SelectWrapper>
					<select
						onChange={(event) => addToCart(product, event.target.value)}
					>
						{options}
					</select>
				</SelectWrapper>
			)
		}
	}

	const renderProducts = () => {
		if (!products || !products?.length) return

		const items	= products.map((product, i) => {
			let hasStock = false
			
			forEach(product.variants, (variant) => {
				if (variant.availableForSale) hasStock = true;
			})

			return (
				<Product
					key={i}
					hoverImage={product.images[1] && product.images[1].originalSrc}
					disabled={!hasStock}
				>
					<Image
						image={product.images[0] && product.images[0].originalSrc}
						hoverImage={product.images[1] && product.images[1].originalSrc}
						onClick={() => hasStock && addToCart(product)}
					/>

					{product.vendor && (
						<Subheading>{product.vendor}</Subheading>
					)}
				
					<Heading
						onClick={() => hasStock && addToCart(product)}
					>
						{product.title}
					</Heading>
					
					{product.variants.length > 1 && renderVariantsDropdown(product)}

					{!hasStock && (
						<Heading>
							Sold out!
						</Heading>
					)}
				</Product>
			)
		})

		return (
			<Grid>
				{items}
			</Grid>
		)
	}

	return (
		<Container>
			{renderProducts()}
		</Container>
	)
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	padding-top: 50px;
	box-sizing: border-box;
	
	${media.phone`
		padding-top: 20px;
	`}
`

// Shared

const Heading = styled.div``
const Subheading = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgIcon};

	${props => {
		if (props.hoverImage) return css`
			&:hover {
				background-image: url(${props => props.hoverImage});
			}
		`
	}}
`

// Grid

const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: calc(100% + 32px);

	${media.phone`
		flex-direction: column;
		align-items: center;
		margin-top: 24px;
		width: 100%;
	`}
`

const AddToCart = styled.div`
	cursor: pointer;
	${hoverState}
	margin-top: 16px;
`

const Product = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0 1 calc(33.3% - 32px);
	padding-bottom: 64px;
	cursor: pointer;

	&:not(:nth-child(3n)) {
		margin-right: 32px;
	}

	${media.desktop`
		flex: 0 1 calc(50% - 32px);
		margin-bottom: 32px;

		&:not(:nth-child(3n)) {
			margin-right: 0;
		}	

		&:nth-child(odd) {
			margin-right: 32px;
		}
	`}

	${media.phone`
		flex: 1 0 100%;
		margin-right: 0 !important;
	`}

	${Heading} {
		&:last-child {
			margin-top: 16px;
		}
	}

	${Image} {
		height: 13vw;
		max-height: 350px;
		min-height: 210px;
		margin-bottom: 24px;
	}

	${Subheading} {
		font-family: 'Cooper';
		font-size: 16px;
		margin-bottom: 10px;
		text-align: center;
	}

	${Heading},
	${AddToCart} {
		font-family: 'Cooper';
		font-size: 24px;
		text-align: center;

		${media.phone`
			font-size: 18px;
			line-height: 24px;
		`}
	}

	/* Image preload */

	${props => {
		if (props.hoverImage) return css`
			background: url(${props => props.hoverImage}) no-repeat -9999px -9999px;
		`
	}}

	/* Disabled */

	${props => {
		if (props.disabled) return css`
			pointer-events: none;
		`
	}}
`

const SelectWrapper = styled.div`
	margin: 24px auto 0; 
	width: 80%;
	position: relative;

	select {
		width: 100%;
		height: 48px;
		font-size: 14px;
		border: 0;
		padding: 0 15px;

		font-family: 'Cooper';
		font-size: 18px;
		text-align: center;
		appearance: none;
		background: none;
		border: 2px solid black;
	}

	&::after {
		content: '↓';
		font-size: 18px;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}
`

Releases.background = blue
Releases.indicator = red
Releases.displayName = 'Shop'
Releases.template = 'shop'

const mapDispatchToProps = dispatch => ({
    fetchCart() {
        dispatch(fetchCart())
    },
    addToCart(cartItem) {
        dispatch(addToCart(cartItem))
	},
	updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    ...getCart(state)
})

export const query = graphql`
    query {
        allShopifyCollection(filter: {handle: {eq: "music-in-exile"}}) {
            nodes {
                title
                handle
                products {
                    handle
                    title
                    tags
                    productType
                    images {
                        originalSrc
                    }
                    priceRange {
                        minVariantPrice {
                            amount
                            currencyCode
                        }
                    }
					variants {
						availableForSale
						compareAtPrice {
							amount
							currencyCode
						}
						id
						image {
							id
							originalSrc
						}
						price {
							amount
							currencyCode
						}
						requiresShipping
						selectedOptions {
							name
							value
						}
						sku
						title
						weight
						weightUnit
					}
                }
            }
        }
    }
`


export default connect(mapStateToProps, mapDispatchToProps)(Releases);