import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { bgIcon, bgImage } from '../styles/global'
import { useMount } from 'react-use'
import { media, isClient } from '../styles/utils'

const DonationModal = (props) => {    
    const [mounted, setMounted] = useState(false)

    useMount(() => {
        setMounted(true)
    })

    return (
        <Wrapper
            mounted={mounted}
        >
             <Donation
                    dangerouslySetInnerHTML={{ __html: `
                        <script src="https://www.givenow.com.au/js/iframe-resizer/iframeResizer.min.js?version=4.3.2.1"></script><div style="padding:1px"><iframe id="iFrameResizer" class="gn-iframe" src="https://www.givenow.com.au/embed/Y2F1c2VpZD04ODcxJmRvbWFpbj1odHRwczovL211c2ljaW5leGlsZS5jb20uYXUvJnRva2VuPTg4NzE6MmQxZTAxMWM4OGFkYjI4Yw%3D%3D" style="width:1px;min-width:100%;border:0;height:882px;"></iframe></div><script>iFrameResize({onScroll:function(x,y){return false;}, checkOrigin:false, onResized:function(iframe){var element = document.getElementById("iFrameResizer");var shouldScroll=element.getAttribute("data-scroll-to-top");var bodyRect=document.body.getBoundingClientRect(),elemRect=element.getBoundingClientRect(),offset=elemRect.top - bodyRect.top;if(shouldScroll==="true" && iframe.height>0 && iframe.type !== 'init'){var offsetBonus=0;window.scrollTo({ top: offset-offsetBonus, behavior: 'smooth' });}else if( iframe.height>0 && iframe.type.indexOf('Observer') >= 0){element.setAttribute("data-scroll-to-top","true");}}}, "#iFrameResizer")</script>
                    `}}
                />
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled.div``
const Items = styled.div``


// Layout

const ScrollWrapper = styled.div``

const Wrapper = styled.div`
    transition: ease-in opacity 0.5s;
    opacity: 0;
    background: white;

    width: calc(100vw - 100px);
    height: calc(100vh - 200px);
    max-width: 700px;
    /* padding: 23px 24px 50px; */
    overflow: hidden;
    border-radius: 20px;
    border: 2px solid black;

    ${media.tablet`
        height: calc(100vh - 128px);
        width: calc(100vw - 32px);
    `}

    justify-content: center;
    align-items: center;

    ${ScrollWrapper} {
        display: flex;
        flex-direction: column;
        height: 1000px;
    }

    ${props => {
        if (props.mounted) return css`
            opacity: 1;
        `
    }}
`

const Donation = styled.div`
	width: 100%;

	iframe {
		width: 100%;
        padding-bottom: 48px;
        height: calc(100vh - 200px) !important;

        ${media.tablet`
            height: calc(100vh - 128px) !important;
        `}
	}
`

export default DonationModal