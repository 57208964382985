import React from 'react'
import styled, { css } from 'styled-components'
import { kebabCase } from 'lodash'

import { media } from '../../../styles/utils'
import { container, bgImage, button } from '../../../styles/global'
import { fbTrack } from '../../../utils'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const BlockReleaseLinks = (props) => {
	const { items } = props;

	const renderLinks = () => {
		if (!items) return;

		return items.map((link, i) => {
			return (
				<ReleaseLink
					key={i}
				>	
					<Subheading>
						{link.name}
					</Subheading>

					<Button
						target={'_blank'}
						href={link.button_link}
						onClick={() => {
							const lowerCaseName = link?.name?.toLowerCase?.()
								
							fbTrack('trackCustom', 'releaseLinkClicked', {
								linkName: link?.name,
								linkSlug: kebabCase(lowerCaseName),
								buttonText: link?.button_text,
								linkURL: link?.button_link,
							})

							trackCustomEvent({
								category: 'Release Link',
								action: 'Click',
								value: kebabCase(lowerCaseName)
							})
						}}
					>
						{link.button_text}
					</Button>
				</ReleaseLink>
			)
		})
	}
        
	return (
		<Wrapper>
			{renderLinks()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Button = styled.a``

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;	
	max-width: 400px;
	width: 100%;
	align-self: center;
	margin: 0 auto;
	margin-top: 48px;
	margin-bottom: 60px;
	border: 3px solid black;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
`

const ReleaseLink = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid black;
	padding: 16px 24px;

	${Button} {
		${button}
		height: 32px;
	}

	${Subheading} {
		font-family: 'Cooper';
		font-size: 21px;
		line-height: 26px;
	}
`


BlockReleaseLinks.wrapper = css`

`

export default BlockReleaseLinks
