import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { VelocityComponent, VelocityTransitionGroup } from 'velocity-react';
import Client from 'shopify-buy';
import NumberFormat from 'react-number-format'

import { Spinner } from '../'
import { fetchCart, getCart, addToCart } from '../../store/cart';
import { updateSettings } from '../../store/settings'
import { getCartQuantity, getCartTotal } from '../../utils'
import { media, width } from '../../styles/utils'
import { container, bgImage, bgIcon, padding, hoverState, line } from '../../styles/global';
import { animIn, animOut, closeAll } from './anim'

const IMAGE_PATH = process.env.NODE_ENV == 'development' ? '/assets/images/' : 'https://d3h5kj9q7mrsm.cloudfront.net/assets/images/';

// Fetch products from Shopify

const client = Client.buildClient({
	storefrontAccessToken: 'aef0f9b15ea83b0891154d98f7841ede',
	domain: 'sound-merch-com-au.myshopify.com',
});

class Menu extends Component {

    state = {
        checkout: false
    }

    componentDidMount = () => {
        this.props.fetchCart();

		client.checkout.create().then((checkout) => {
			this.setState({
				checkout: checkout
			})
		});
    }

    componentWillUpdate = (nextProps, nextState) => {
        const { cart, cartActive, menuActive, toggleCart, stockistsActive } = this.props;   
                
        // Assign correct animations  

        let newAnimation;

        if (cartActive != nextProps.cartActive && (nextProps.cartActive)) {
            newAnimation = animIn(nextProps.cartActive)                
        }

        if (cartActive != nextProps.cartActive && (!nextProps.cartActive)) {
            newAnimation = animIn(nextProps.cartActive)                
        }

        if (cartActive != nextProps.cartActive && !nextProps.cartActive && !nextProps.menuActive) {
            newAnimation = animOut(nextProps.menuActive)                
        }

        if (cartActive != nextProps.cartActive && menuActive != nextProps.menuActive) {
            newAnimation = nextProps.menuActive ? animIn(nextProps.cartActive) : animOut(nextProps.menuActive);        
        }      

        if (menuActive != nextProps.menuActive) {
            newAnimation = nextProps.menuActive ? animIn(nextProps.cartActive) : closeAll();        
        }      

        if (nextProps.cartActive && stockistsActive) {
            newAnimation = animIn(nextProps.cartActive, true);
        }        

        if (newAnimation) {
            this.setState({
                anim: newAnimation
            })
        }

    }
    
    renderCartItems = () => {
        const { cart } = this.props;
        
        let cartItems;

        if (cart && cart.length > 0) {
            cartItems = cart.map((item, i) => {

                return (
                    <CartItem
                        key={i}
                    >
                        <Title>{item.title}</Title>
                        <Quantity>x {item.quantity}</Quantity>
                        <Spinner
                            cartItem={item}
                        />
                        <Value>
                            <NumberFormat 
                                value={item.total * item.quantity} 
                                prefix={'$'}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                decimalScale={2}
                                fixedDecimalScale={true}      
                             />
                        </Value>
                        
                    </CartItem>
                )
            }); 
        }   

        return (
            <CartItems>
                {cartItems}
            </CartItems>
        )
    }   

    calculateTotal = () => {
        const { cart } = this.props;
        return getCartTotal(cart);
    }  
    
    toggleCart = () => {
        const { updateSettings } = this.props;
        updateSettings({
            cartActive: false
        })
    }  

    goToCheckout = () => {
        const { cart } = this.props;
        const { checkout } = this.state;

        document.body.style.cursor = "wait";

        let lineItemsToAdd = [];

        cart.forEach((item, i) => {
            lineItemsToAdd.push({
                variantId: item.id,
                quantity: item.quantity
            })
        })

        client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((checkout) => {		
			window.location.href = checkout.webUrl;
		});
    }
    
    render() {
        const { cart, cartActive, menuActive, toggleCart, stockistsActive } = this.props;   
        const { anim } = this.state;   

        return (
            <Wrapper
                cartActive={cartActive}
            >

                <Container>

                    <Header>
                        <HeaderLabel>Cart</HeaderLabel>
                        <Close 
                            onClick={this.toggleCart}
                        >
                            ×
                        </Close>
                    </Header> 

                    <Line
                        marginBottom={true}
                    />

                    {this.renderCartItems()}

                    <Line
                        marginBottom={true}
                    />    

                    <Subtotal>
                        <Label>Subtotal</Label>
                        <Total>
                            <NumberFormat 
                                value={this.calculateTotal()} 
                                prefix={'$'}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                decimalScale={2}
                                fixedDecimalScale={true}      
                            />
                        </Total>
                    </Subtotal>

                    <Line
                        marginBottom={true}
                    />
            
                    <Checkout
                        onClick={this.goToCheckout}
                        hasItems={cart && cart.length > 0}
                    >
                        <Label>Continue to Checkout</Label>
                        <Arrow>&#x21E6;</Arrow>
                    </Checkout>
                
                </Container>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0 auto;
    height: 100vh;
    width: 50vw;
    background: #1A1A1A;

    position: fixed;
    top: 0;
    left: 50vw;
    bottom: 0;
    z-index: 20;

    display: ${props => props.cartActive ? 'flex' : 'none'};
    pointer-events: ${props => props.cartActive ? 'all' : 'none'};
    overflow: hidden;
    font-family: 'Cooper';

    font-size: 32px;
    margin-bottom: 16px;

    ${media.phone`
        font-size: 18px;
    `}

    ${media.tablet`
        width: 100vw;
        left: 0;
    `}
`

const Container = styled.div`
    ${container}
    ${padding}
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 50vw;

    ${media.tabletLandscape`
        min-width: 100vw;
    `}

    ${media.phone`
        padding-bottom: 128px;
    `}
`

const Header = styled.div`
    display: flex;
    align-items: center;
    height: 90px;
    padding-top: 1px;

    ${media.phone`
        height: 70px;
    `}
`;

const Close = styled.div`
    ${hoverState}
    color: white;
    margin-left: auto;
    cursor: pointer;
`;

const HeaderLabel = styled.div`
    color: white;
`

const Currency = styled.div`
    margin-left: auto;
`;

const Line = styled.div`
    ${line}
`;

const CartItems = styled.div`
    flex: 1;
    margin-top: 4px;
    border-top: 2px solid white;
    padding-top: 32px;
`

const CartItem = styled.div`
    display: flex;
    justify-content: space-between;
    color: white;
    
    &:not(:last-child) {
       margin-bottom: 24px;

        ${media.phone`
            margin-bottom: 24px;
        `}
    }
    
    & >:not(:last-child) {
        margin-right: 60px;

        ${media.phone`
            margin-right: 25px;
        `}
    }
`

const Meta = styled.div`
    user-select: none;
    color: white;
    font-size: 18px;
`

const Title = styled(Meta)`
    flex: 1;
`

const Quantity = styled(Meta)``
const Value = styled(Meta)``

const Panel = css`
    display: flex;
    justify-content: space-between;
`

const Subtotal = styled.div`
    ${Panel}
    margin-bottom: 64px;
`

const Label = styled.div`
    color: white;
`

const Total = styled.div`
    color: white;
`

const Checkout = styled.div`
    ${Panel}
    ${hoverState}
    pointer-events: ${props => props.hasItems ? 'all' : 'none'};
    opacity: ${props => props.hasItems ? 1 : 0.6};
    cursor: ${props => props.hasItems ? 'pointer' : 'default'};
`

const Arrow = styled.div`
    transform: rotate(-180deg);
    color: white;
`

const mapDispatchToProps = dispatch => ({
    fetchCart() {
        dispatch(fetchCart())
    },
    addToCart(cartItem) {
        dispatch(addToCart(cartItem))
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    ...getCart(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu);