import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery } from "gatsby";
import styled from 'styled-components'
import { Header, Footer, Stockists } from '../'

import '../../styles/global'
import '../../styles/fonts'

const Layout = ({ children, ...props }) => {
    const data = useStaticQuery(query);

    return (
        <Wrapper>
            <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                    { name: 'description', content: data.site.siteMetadata.description},
                    { name: 'keywords', content: data.site.siteMetadata.keywords },
                    { property: 'og:url', content: data.site.siteMetadata.url },
                    //{ property: 'og:image', content: ogProfileImage },
                    { property: 'og:title', content: data.site.siteMetadata.title },
                    { property: 'og:description', content: data.site.siteMetadata.description },
                    { property: 'twitter:card', content: 'summary' },
                    { property: 'twitter:title', content: data.site.siteMetadata.title },
                    { property: 'twitter:description', content: data.site.siteMetadata.description}
                ]}
            />

            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Roboto+Mono" rel="stylesheet"/>
                <meta name="facebook-domain-verification" content="nsax90ui1exo4wsp9ki33rk9d94scf" />
            </Helmet>

            {children}
        </Wrapper>
    )
}
const Wrapper = styled.div`
    height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export default Layout

export const query = graphql`
	query SiteTitleQuery {
		site {
			siteMetadata {
                title,
                description,
                keywords,
                url
			}
		}
	}
`
