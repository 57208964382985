import React from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import BandcampPlayer from 'react-bandcamp'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

class BlockEmbed extends React.Component {
	
	render() {
        const { content, noPadding, bandcamp_album_id } = this.props;
        
		return (
            <Container
				noPadding={noPadding}
			>
				{content && (
					<Player
						url={content}
					/>
				)}

                {bandcamp_album_id && (
					<BandcampPlayer 
						album={bandcamp_album_id} 
						width={'100%'}
						height={'120px'}
						artwork={'small'}
					/>
				)}

            </Container>
		)
	}
}

const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${props => !props.noPadding && padding};
	${container};
`

const Player = styled(ReactPlayer)`
	width: 100% !important;
	height: 30vw !important;
	min-height: 320px;
	border-radius: 8px;
	overflow: hidden;
`


BlockEmbed.wrapper = css`

`

export default BlockEmbed
