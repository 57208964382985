import React from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { orderBy } from 'lodash'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import Post from '../Post/Post';
import { red, green } from '../../styles/colors';

class News extends React.Component {

	state = {
	
	}

	componentWillMount = () => {
		const data = JSON.parse(this.props.data.wordpressNews.news)
		
		this.setState({
			news: orderBy(data.news, 'publishedDate', 'desc'),
		})
	}

	componentDidMount = () => {
		if (isClient() ) {
			window.history.pushState(null, null, '/news');
		}
	}

	navigateToNews = (slug) => {
		const { updateRoute } = this.props;
		
		updateRoute('left',
			<Post 
				slug={slug} 
			/>
		)
	}

	renderNews = () => {
		const { news } = this.state;

		const items	= news.map((news, i) => {
			return (
				<NewsItem
					key={i}
					onClick={() => this.navigateToNews(news.slug)}
				>
					<Post 
						slug={news.slug} 
						data={this.props.data}
					/>
				</NewsItem>
			)
		})

		return (
			<Grid>
				{items}
			</Grid>
		)
	}
	
	render() {
		const { background, type } = this.props;
		
		return (
			<Container>
				{this.renderNews()}
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	box-sizing: border-box;
`

// Shared

const Heading = styled.div``
const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
`

const Grid = styled.div`
	display: flex;
	flex-direction: column;
`

const NewsItem = styled.div`
	cursor: pointer;
`


export const pageQuery = graphql`
	fragment news on Query {
		wordpressNews {
			news 
		}
	}
`

News.background = red
News.indicator = green
News.displayName = 'News'
News.template = 'news'

export default News
