import axios from 'axios';

export function fetchData(endpoint, config, debug, noCache) {    

    if (noCache) {
        endpoint = `${endpoint}?=${Date.now()}`
    }

    return new Promise((resolve, reject) => {
        axios.get(endpoint, config)
            .then((response) => {
                if (response.data) {
                    debug && console.log(response.data)
                    resolve(response.data);
                }
            })
            .catch(error =>  {
                console.log(error)
                
                reject(error)
            })      
    })
}
