export const grey = '#dadada'

// Palette

export const red = '#F15A48'
export const pink = '#FDBAA0'
export const yellow = '#FFDE02'
export const blue = '#0182C5'
export const green = '#569250'


