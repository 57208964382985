import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { container, bgIcon, bgImage } from '../../../styles/global'
import { fbTrack } from '../../../utils'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const BlockVideo = (props) => {
    const { url } = props;

    const renderVideo = () => {
        if (!url) return;

        return (
            <Player
                url={url}
                height={'100%'}
                width={'100%'}
                onPlay={() => {
                    fbTrack('trackCustom', 'videoPlayed', {
                        videoURL: url,
                    })

                    trackCustomEvent({
                        category: 'Video Played',
                        action: 'Click',
                        value: url
                    })
                }}
            />
        )
    }
            
	return (
		<Wrapper>
            {renderVideo()}
		</Wrapper>
	)
}


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* padding-bottom: 60.65%;
    margin-bottom: 30px; */
`


const Player = styled(ReactPlayer)`
	width: 100% !important;
	border-radius: 8px;
	overflow: hidden;
    padding-bottom: 60.65%;
    position: relative;
    
    > * {
       position: absolute;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
    }
`

BlockVideo.wrapper = css`

`

export default BlockVideo
7