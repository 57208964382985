import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import { media } from '../../../styles/utils'
import { container, bgImage, button } from '../../../styles/global'

const BlockLinkGrid = (props) => {
	const { items } = props;
		
	const renderLinks = () => {
		if (!items) return;

		return items.map((item, i) => {
			return (
				<Item
					key={i}
				>	
					{item.image && (
						<Image
							key={item.image.id}
							src={item.image.sizes.medium2}
						>
							{(src, loading) => {
								return (
									<BGImage 
										image={src} 
										style={{opacity: loading ? 0 : 1}}  
									/>
								)
							}}
						</Image> 
					)}

					<Subheading
						dangerouslySetInnerHTML={{__html: item.subheading}}
					/>

					<Text
						dangerouslySetInnerHTML={{__html: item.text}}
					/>

					{item.use_link && (
						<Button
							href={item.use_link ? item.link : ''}
							target={'_blank'}
						>
							{item.link_text}
						</Button>
					)}
				</Item>
			)
		})
	}

	return (
		<Wrapper>
			{renderLinks()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Button = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
    width: 100%;
`

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: calc(100% + 40px);
`

const Item = styled.div`
	display: flex;
	flex-direction: column;	
	flex: 0 1 calc(25% - 40px);
	flex-basis: calc(25% - 40px);
	margin-right: 40px;
	margin-bottom: 40px;

	${BGImage} {
		padding-bottom: 100%;
		width: 100%;
		margin-bottom: 23px;
	}

	${Subheading} {
		font-family: Helvetica, Arial, sans-serif;
		font-size: 24px;
		line-height: 28px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 40px;

		${media.tablet`
			font-size: 18px;
			line-height: 21px;
			margin-bottom: 20px
			text-align: center;
		`}
	}

	${Text} {
		font-family: 'Cooper';
		font-size: 26px;
		line-height: 31px;

		p:first-child {
			margin-top: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}

		${media.tablet`
			font-size: 18px;
			line-height: 22px;
		`}

		${media.phone`
			font-size: 14px;
			line-height: 17px;
		`}
	}

	${Button} {
		${button};
		margin-top: 32px;
		height: 48px;
		align-self: center;
		min-width: 100px;
	}

	${media.tablet`
		flex: 0 1 calc(50% - 20px);
		flex-basis: calc(50% - 20px);
		margin-right: 20px;
	`}
`

BlockLinkGrid.wrapper = css`
	overflow-x: hidden;
`

export default BlockLinkGrid
