import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const BlockText = (props) => {
	const { content } = props;
        
	return (
		<Wrapper>
			<Text
				dangerouslySetInnerHTML={{__html: content}}  
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
    display: flex;
`

const Text = styled.div`
	font-family: 'Cooper';
	font-size: 26px;
	line-height: 31px;

	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	${media.tablet`
		font-size: 18px;
		line-height: 22px;
	`}
`

BlockText.wrapper = css`
	display: flex;
	justify-content: center;
`

export default BlockText
