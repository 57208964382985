import React from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { find } from 'lodash'
import moment from 'moment'
import Helmet from 'react-helmet'
import { kebabCase } from 'lodash'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, button } from '../../styles/global'
import { red, green } from '../../styles/colors';
import { fbTrack } from '../../utils'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

class Post extends React.Component {

	state = {
	
	}
	
	componentWillMount = () => {
		const { data } = this.props;
		const news = JSON.parse(data.wordpressNews.news)
						
		this.setState({
			data: find(news.news, {slug: this.resolveSlug()})
		})
	}

	resolveSlug = () => {
		const { slug } = this.props;
		const pathContext = this.props.pathContext;

		if (slug) return slug;
		if (pathContext.slug) return pathContext.slug;
	}
	
	componentDidMount = () => {
		const { slug, news } = this.props;
		
		if (isClient()) {
			window.history.pushState(null, null, `/news/${this.resolveSlug()}`);
		}
	}

	renderReleaseLinks = () => {
		const { data } = this.state;

		if (!data.acf.release_links) return;

		const items	= data.acf.release_links.map((link, i) => {
			const slug = this.resolveSlug();

			return (
				<ReleaseLink
					key={i}
				>	
					<Subheading>
						{link.name}
					</Subheading>

					<Button
						target={'_blank'}
						href={link.button_link}
						onClick={() => {
							const lowerCaseName = link?.name?.toLowerCase?.()
								
							fbTrack('trackCustom', 'releaseLinkClickedNews', {
								linkName: link?.name,
								linkSlug: kebabCase(lowerCaseName),
								buttonText: link?.button_text,
								linkURL: link?.button_link,
								newsSlug: slug
							})

							trackCustomEvent({
								category: 'Release Link (News)',
								action: 'Click',
								value: kebabCase(lowerCaseName)
							})
						}}
					>
						{link.button_text}
					</Button>
				</ReleaseLink>
			)
		})

		return (
			<ReleaseLinks>
				{items}
			</ReleaseLinks>
		)
	}

	renderEvents = () => {
		const { data } = this.state;

		if (!data.acf.event_dates) return;

		const items	= data.acf.event_dates.map((event, i) => {
			return (
				<EventItem
					key={i}
					soldOut={event.sold_out}
				>
					<Title>{event.title}</Title>
					
					{!event.sold_out && (
						<TicketLink
							href={event.link}
							target={'_blank'}
						>
							Buy Ticket
						</TicketLink>
					)}
					
					{event.sold_out && (
						<Title>Sold out</Title>
					)}
				</EventItem>
			)
		})

		return (
			<Events>
				<Heading>Dates</Heading>
				{items}
			</Events>
		)
	}

	render() {
		const { data } = this.state;

		return (
			<Container>
				<Helmet>
					<title>{data.title} — Music in Exile</title>
					<meta name="description" content={data.acf.content.replace(/<\/?[^>]+(>|$)/g, "")}/>
				</Helmet>

				<Date>
					{moment.unix(data.publishedDate).format('D MMM YY')}
				</Date>

				<Image
					image={data.acf.hero_image && data.acf.hero_image.sizes.medium2}
				/>

				<Heading>{data.title}</Heading>

				<Content
					dangerouslySetInnerHTML={{__html: data.acf.content}}
				/>

				{this.renderReleaseLinks()}
				{this.renderEvents()}
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	padding-top: 55px;
	padding-bottom: 128px;

	${media.phone`
		padding-top: 26px;
	`}
`

// Shared

const Heading = styled.div`
	font-family: 'Cooper';
	font-size: 30px;
	margin-top: 32px;

	${media.phone`
		font-size: 24px;
	`}
`

const Title = styled.div``
const Description = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
	min-height: 420px;
	height: 30vw;
	width: calc(100% + 64px);
	transform: translateX(-32px);
	mix-blend-mode: multiply;
	filter: grayscale(100%);

	&:hover {
		mix-blend-mode: normal;
		filter: grayscale(0%);
	}
`

// Top

const Date = styled.div`
	font-family: 'Cooper';
	font-size: 21px;
	line-height: 26px;
	text-transform: uppercase;
	margin-bottom: 20px;

	${media.phone`
		font-size: 16px;
	`}
`

// Content

const Content = styled.div`
	font-family: 'Cooper';
	font-size: 21px;
	margin: 30px 0;
	line-height: 26px;

	p {
		margin-bottom: 28px;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`


// News

const Events = styled.div`
	margin-top: 40px;

	${Heading} {
		margin-bottom: 26px;
	}
`

const EventItem = styled.div`
	font-family: 'Cooper';
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 48px;
	font-size: 21px;
	border-top: 2px solid black;
	padding: 10px 10px;

	${Title} {
		flex: 0 1 auto;
		font-family: 'Roboto Mono', monospace;
		font-size: 16px;
		text-transform: uppercase;
		margin-right: 32px;
		transform: translateY(-2px);
		max-width: 60%;
	}

	&:last-child {
		border-bottom: 3px solid black;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;

		a {
			flex: 1 0 auto;
		}
	`}

	${props => {
		if (props.soldOut) return css`
			pointer-events: none;

			${Title}:first-child {
				text-decoration: line-through;
			}
		`
	}}
`

const TicketLink = styled.a`
	${hoverState}
	${button}
`

// Release Links

const ReleaseLinks = styled.div`
	display: flex;
	flex-direction: column;	
	max-width: 400px;
	align-self: center;
	margin: 0 auto;
	margin-top: 96px;
	margin-bottom: 48px;
	border: 3px solid black;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
`

const Button = styled.a``
const Subheading = styled.div``

const ReleaseLink = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid black;
	padding: 16px 24px;

	${Button} {
		${button}
		height: 32px;
	}

	${Subheading} {
		font-family: 'Cooper';
		font-size: 21px;
		line-height: 26px;
	}
`

Post.displayName = 'News'
Post.background = red
Post.indicator = green
Post.template = 'post'

export default Post
