import React, { Fragment } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { forEach } from 'lodash'
import { Block } from '../../components'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import Post from '../Post/Post';
import { red, green } from '../../styles/colors';

class Music extends React.Component {

	state = {
	
	}

	componentWillMount = () => {
		const data = JSON.parse(this.props.data.wordpressArtists.artists)
		
		this.setState({
			artists: data.artists,
		})
	}

	componentDidMount = () => {
		if (isClient() ) {
			window.history.pushState(null, null, '/music');
		}
	}

	// navigateToNews = (slug) => {
	// 	const { updateRoute } = this.props;
		
	// 	updateRoute('left',
	// 		<Post 
	// 			slug={slug} 
	// 		/>
	// 	)
	// }

	renderBlocks = (blocks) => {
		if (!blocks) return;

		return blocks.map((block, i) => {  
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					{...block}
				/>
			)
		})
	}

	getEmbedBlocks = (blocks) => {
		let items = []

		forEach(blocks, (block) => {
			if (block.acf_fc_layout == 'embed') items.push(block)
			if (block.acf_fc_layout == 'bandcamp') items.push(block)
		})

		return items
	}

	renderBlocks = (blocks) => {
		if (!blocks) return;

		return blocks.map((block, i) => {  
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					noPadding={true}
					{...block}
				/>
			)
		})
	}
	
	renderLinks = (data) => {
		if (!data.acf.social_links) return;

		const items	= data.acf.social_links.map((link, i) => {
			return (
				<Fragment>
					<SocialLink
						key={i}
						target={'_blank'}
						href={link.link}
					>
						{link.name}
					</SocialLink>
					{i < (data.acf.social_links.length - 1) && '|'}
				</Fragment>
			)
		})

		return (
			<Links>
				{items}
			</Links>
		)
    }
	
	renderNews = () => {
		const { artists } = this.state;

		const items	= artists.map((artist, i) => {
			const blocks = this.getEmbedBlocks(artist.acf.blocks);
			if (!blocks.length) return;

			console.log(artist.acf)

			return (
				<Artist
					key={i}
					// onClick={() => this.navigateToNews(news.slug)}
				>
					<Heading>
						{artist.title}
					</Heading>

					{this.renderLinks(artist)}

					{artist.acf.listen_page_text && (
						<TextLinks
							dangerouslySetInnerHTML={{__html: artist.acf.listen_page_text}}
						/>
					)}
					
					{this.renderBlocks(blocks)}
				</Artist>
			)
		})

		return (
			<Grid>
				{items}
			</Grid>
		)
	}
	
	render() {
		const { background, type } = this.props;
		
		return (
			<Container>
				{this.renderNews()}
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	box-sizing: border-box;
	padding-top: 55px;

	${media.phone`
		padding-top: 30px;
	`}
`

// Shared


const Heading = styled.div`
	font-family: 'Cooper';
	font-size: 30px;

	${media.phone`
		font-size: 18px;
	`}
`

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
`

const Grid = styled.div`
	display: flex;
	flex-direction: column;
`

const Artist = styled.div`
	margin-bottom: 96px;

	${media.phone`
		margin-bottom: 32px;
	`}
`

// TextLinks

const TextLinks = styled.div`
	margin-top: 32px;
	margin-bottom: 32px;
	font-family: 'Cooper';
	font-size: 21px;
`

// Link

const SocialLink = styled.a`
	font-family: 'Cooper';
	display: flex;
	align-items: center;
	font-size: 21px;

	&:not(:first-child) {
		margin-left: 10px;
	}

	&:not(:last-child) {
		margin-right: 10px;
	}
`

const Links = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
`


export const pageQuery = graphql`
	fragment artistMusic on Query {
		wordpressArtists {
			artists 
		}
	}
`


Music.background = red
Music.indicator = green
Music.displayName = 'Music'
Music.template = 'music'

export default Music
