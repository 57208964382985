import React from 'react'
import styled from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { Link, navigate } from 'gatsby'
import logo from '../../assets/images/logo.svg'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { ribbon } from '../../styles/colors';

class Header extends React.Component {

	state = {
		pageLoaded: false
	}
	
	componentWillMount = () => {
		const { fadeIn } = this.props; 

		if (!fadeIn) { 
			this.setState({
				pageLoaded: true
			})
		}
	}
	
	componentDidMount() {
		const { fadeIn } = this.props;
		
		if (fadeIn) {
			// Animate load

			setTimeout(() => {
				this.setState({
					pageLoaded: true
				})
			}, 0)
		}
	}
	
	render() {
		const { pageLoaded } = this.state;
		const { scrollToRef, fadeIn } = this.props;
        
        //const isHomepage = location.pathname === '/';

		return (

				<Container>

					<Logo onClick={() => navigate('/')}/>
                    
                    <Navigation>
                        {/* <NavLink onClick={() => navigateTo('/#about')}>About</NavLink> */}
                    </Navigation>
            
				</Container>

	
		)
	}
}


const Container = styled.div`
	${container}
	${padding}
	height: auto;
	align-items: flex-end;
	padding-top: 52px;
	opacity: 0;
	z-index: 99;

	${media.tablet`
		padding-top: 35px;
	`}

	${media.phone`
		align-items: center;
	`}
`

const Logo = styled.div`
	height: 31px;
	width: 91px;
	background-image: url(${logo});
	${bgIcon}
	margin-right: 60px;
	transform: translateY(-1px);
	cursor: pointer;
	
	${media.tablet`
		height: 21px;
		width: 62px;
	`}

	${media.desktop`
		height: 25px;
		width: 74px;
	`}
`

const Navigation = styled.div`
	display: flex;

	${media.tablet`
		display: none;
	`}
`

const NavLink = styled.a`
	display: flex;
	font-size: 21px;
	color: #292b32;
	${hoverState}
	cursor: pointer;

	&:not(:last-child) {
		margin-right: 40px;	
    }

    ${hoverState}

    &:hover {
        cursor: pointer;
    }
`





export default Header
