import React, { Fragment } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { find } from 'lodash'
import { Block } from '../../components'
import moment from 'moment'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import Post from '../Post/Post';
import { red, green, yellow, blue } from '../../styles/colors';
import { fetchData } from '../../services/api'

const SIMPLECAST_SHOW = 'e8006388-d510-46e6-87af-afd4ab818846'
const SIMPLECAST_API = `https://api.simplecast.com/podcasts/${SIMPLECAST_SHOW}`

class Radio extends React.Component {

	state = {
		data: null
	}

	componentWillMount = () => {
		const data = JSON.parse(this.props.data.wordpressRadio.acf_json)
		this.fetchPodcasts()
		
		this.setState({
			data
		})
	}

	fetchPodcasts = () => {
		const config = {
			headers: {
				'Authorization': `Bearer eyJhcGlfa2V5IjoiMzlkYjQ5YzlhNTdmZTMzYjVmODg4OWEzYzk5ZDc5MjMifQ==`,
				'Accept': 'application/json'
			}
		}

		let self = this;

		fetchData(`${SIMPLECAST_API}/episodes`, config)
			.then((res) => {			
				if (res) {
					self.setState({
						podcasts: res.collection
					})
				}
			}) 
	}

	componentDidMount = () => {
		if (isClient() ) {
			window.history.pushState(null, null, '/radio');
		}
	}

	renderCalendar = () => {
		return (
			<Calendar>
				<Heading>Calendar</Heading>
				
				<img
					src={'https://previews.dropbox.com/p/thumb/AAz9X9I0huajB8y4Uo9WtJsAQIyY-ZuV6RmUaq_pG24wzANTw-5kiZY8tpz_wTwHvVnjyShGTxLZRWF8UwJmLg0tO9qO-g6KZCBRSkWw5TbA4EO17sJdTdLHOzjKT5vMnbCPbSF_VtTjK1wQS4yi6d2rqB_c6cE2ZwnOI1PBtG9n3MGJyESVejdE_sg1sMLizHmYjr7rQ2mDov4LCBgtWLCUNvM1daSWjbZN_giSSmN1SVkdETPtvRWd9KGCn6l8Yx4P9F8tmqaKjRqJqDxk4Ykqiwz6kLNF0cOHTfge3-koTt4VFPxaO3Hvpkylq4kfTxmIm5UlheAkpXsZo7mDPWeH3-Uwr3oNuHbXwXgMFvnKJeC9TcsRBfW7GrGcCOA2uYy-r8LKP4G9K-N5uqPcQxpRBzjL-4L5mCSoXgbFjv7ez6mpX_fy-duCVGBzaqb_VLg/p.png?fv_content=true&size_mode=5'}
				/>
			</Calendar>
		)
	}

	renderShowLinks = (show) => {
		if (!show.listen_links) return;

		const platformLinks = [
			{
				name: 'spotify',
				link: '#'
			},
			{
				name: 'apple-podcasts',
				link: '#'
			},
			{
				name: 'soundcloud',
				link: '#'
			},
		]
		
		const items = show.listen_links.map((platform, i) => {
			const { icon, link } = platform;

			return (
				<PlatformLink
					href={link}
					target={'_blank'}
				>
					<Icon
						className={'icon'}
						image={icon.sizes && icon.sizes.large}
					/>
				</PlatformLink>
			)
		})

		return (
			<Items>
				{items}
			</Items>
		)
	}

	getPodcast = (url) => {
		if (!url) return {}
		const index = url.lastIndexOf("/")
		const slug = url.substr((index + 1))
		return find(this.state.podcasts, {slug: slug})
	}
		
	renderPreviousShows = () => {
		const { data, podcasts } = this.state;
		if (!data.previous_episodes || !podcasts) return;

		const items	= data.previous_episodes.map((show, i) => {
			const podcast = this.getPodcast(show.simplecast_short_link)
			
			return (
				<Show>
					<ShowHeader>
						<ShowTitle>
							<Title>{podcast && podcast.title}</Title>
							
							{show.subtitle && (
								<Subheading>{show.subtitle}</Subheading>
							)}
						</ShowTitle>
					
						<ShowLinks>
							{this.renderShowLinks(show)}

							<Title
								as={'a'}
								target={'_blank'}
								href={podcast && podcast.enclosure_url}
							>
								Download
							</Title>
						</ShowLinks>
					</ShowHeader>

					{podcast && (
						<Frame 
							frameborder="no" 
							scrolling="no" 
							seamless 
							src={`https://player.simplecast.com/${podcast.id}?dark=false`}
						/>
					)}
				</Show>
			)
		})

		return (
			<PreviousShows>
				<Heading>Previous Episodes</Heading>
				{items}
			</PreviousShows>
		)
	}

	renderListenOn = () => {
		const { data } = this.state;
		if (!data.subscribe_listen_links) return;

		const items = data.subscribe_listen_links.map((platform, i) => {
			const { icon, link } = platform;

			return (
				<PlatformLink
					href={link}
					target={'_blank'}
				>
					<Icon
						className={'icon'}
						image={icon.sizes && icon.sizes.large}
					/>
				</PlatformLink>
			)
		})

		return (
			<ListenOn>
				<Heading>Subscribe and listen wherever you get your podcasts:</Heading>
				<Items>
					{items}
				</Items>
			</ListenOn>
		)
	}

	render() {	
		const { data } = this.state;

		return (
			<Container>		

				{data.hero_image && (
					<Image
						image={data.hero_image.sizes && data.hero_image.sizes.large}
					/>
				)}

				<Content
					dangerouslySetInnerHTML={{__html: data.description}}
				/>		

				{this.renderListenOn()}
				{this.renderPreviousShows()}
			</Container>
		)
	}
}



// Shared

const Title = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Items = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
`

const Heading = styled.div`
	font-family: 'Cooper';
	font-size: 30px;

	${media.phone`
		font-size: 18px;
	`}
`

// Layout

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	box-sizing: border-box;
	padding-top: 55px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> ${Image} {
		${bgIcon}
		min-height: 320px;
		height: 22vw;
		width: 100%;

		${media.phone`
			min-height: 180px;
		`}
	}

	${media.phone`
		padding-top: 30px;
	`}
`

const Content = styled.div`
	margin-bottom: 48px;

	${media.phone`
		margin-bottom: 24px;
	`}

	> p {
		text-align: center;
	}

	p {
		margin-bottom: 28px;
		font-family: 'Cooper';
		font-size: 21px;
		line-height: 26px;
	}

	${media.phone`
		p {
			font-size: 18px;
			line-height: 24px;
		}
	`}
`

// Listen On

const PlatformLink = styled.a`
	${hoverState}
`

const ListenOn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 94px;
	text-align: center;

	${media.phone`
		margin-bottom: 85px;
	`}

	${Heading} {
		margin-bottom: 24px;
		font-size: 24px;
	}

	${Items} {
		display: flex;
		
		${PlatformLink} {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			/* background: rgba(255,255,255, 0.25); */
				
			font-family: 'Cooper';
			font-size: 20px;
		
			padding: 10px 20px;
			min-height: 32px;
			border: 2px solid black;
			border-radius: 12px;

			.icon {
				width: 32px;
				height: 32px;
			}

			&:not(:last-child) {
				margin-right: 16px;
			}
		}
	}

`

// Calendar

const Calendar = styled.div`
	width: 100%;
	margin-bottom: 32px;

	${Heading} {
		padding-bottom: 20px;
		margin-bottom: 24px;
		border-bottom: 2px solid black;
		flex: 1 0 100%;
		display: none;
	}

	img {
		max-width: 100%;
	}
`

// Previous Shows

const PreviousShows = styled.div`
	width: 100%;

	${Heading} {
		padding-bottom: 20px;
		margin-bottom: 24px;
		border-bottom: 2px solid black;
		flex: 1 0 100%;

		${media.phone`
			font-size: 24px;
		`}
	}
`

// Show

const ShowHeader = styled.div``
const ShowTitle = styled.div``
const ShowLinks = styled.div``

const Date = styled.div``

const Show = styled.a`
	font-family: 'Cooper';
	display: flex;
	flex-direction: column;
	min-height: 48px;
	font-size: 21px;
	padding: 14px 0 24px;
	flex: 0 1 50%;
	margin-bottom: 24px;
	border-bottom: 1px solid black;

	${ShowHeader} {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 32px;

		${media.phone`
			flex-direction: column;		
			align-items: stretch;
		`}

		${ShowTitle} {
			display: flex;
			flex-direction: column;

			${Title} {
				text-transform: capitalize;
				font-size: 22px;
			}

			${Subheading} {
				font-size: 16px;			
				margin-top: 8px;
				max-width: 70%;

				${media.phone`
					max-width: 100%;
					margin-top: 12px;
				`}
			}
		}

		${ShowLinks} {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			${media.phone`
				flex-direction: row;
				justify-content: space-between;
			`}

			${Title} {
				font-family: 'Roboto Mono', monospace;
				font-size: 14px;
				letter-spacing: 1px;
				text-transform: uppercase;
				margin-top: 12px;
			}

			${Items} {
				display: flex;
			
				${PlatformLink} {
					&:not(:last-child) {
						margin-right: 10px;
					}
				}
			}
		}
	}
	
	
	${Description} {
	    white-space: break-spaces;
		font-size: 18px;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`


const Frame = styled.iframe`
	width: 100%;
	height: 150px;

	.simplecast-player {
		.main {
			background: ${yellow} !important;
		}
	}
`

// Icon

const Icon = styled.div`
	background-image: url(${props => props.image});
	width: 27px;
	height: 27px;
	${bgIcon}
`

Radio.background = blue
Radio.indicator = green
Radio.displayName = 'Radio'
Radio.template = 'radio'

export const pageQuery = graphql`
	fragment radio on Query {
		wordpressRadio {
			acf_json 
		}
	}
`

export default Radio
