import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgImage } from '../../../styles/global'

const Block = (props) => {
	const { paragraph } = props;
        
	return (
		<Wrapper>
			Block
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
`

// Layout

const Wrapper = styled.div`

`

Block.wrapper = css`

`

export default Block
