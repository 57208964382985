import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils';

const LandingBlock = (props) => {
	const { layout, lastBlock, block_type, inAccordion, wrapContainer, nextBlock } = props;
	const BlockLayout = resolveBlock(layout);

	return (
		<Wrapper
			className={`block_${layout}`}
			layout={layout}
			blockType={block_type}
			blockProps={props}
			styles={BlockLayout && BlockLayout.wrapper}
			lastBlock={lastBlock}
			nextBlock={nextBlock}
			inAccordion={inAccordion}
		>
			{BlockLayout && (
				wrapContainer ? 
					<Container
						className={'block-container'}
					>
						<BlockLayout {...props}/>
					</Container>					
				:	
					<BlockLayout {...props}/>
			)}
		</Wrapper>
	)
} 

const reducePadding = ['image', 'image_double', 'image_text']

const Wrapper = styled.div`
    width: 100%;
	margin-bottom: 60px;

	${media.tablet`
		margin-bottom: 32px;
	`}

	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}

	/* Reduce margin for sequential image blocks */

	${props => {

		if (
			props.nextBlock 
			&& reducePadding.includes(props.layout)
			&& reducePadding.includes(props.nextBlock.acf_fc_layout)
		) return css`
			margin-bottom: 20px;
		`
	}}

`

const Container = styled.div`
	max-width: 880px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
`

export default LandingBlock
