import React, { useState, useEffect, createContext } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { useMount, useUnmount } from 'react-use'

export const modalContext = createContext()

const Modal = (props) => {
    const { className, children, styles, modal: Modal, disableWrapper, ...rest } = props
    const [mounted, setMounted] = useState(false)

    useMount(() => {
        if (!disableWrapper) document.body.style.overflow = 'hidden'

        props?.onOpen?.()
        setTimeout(() => setMounted(true), 0)
    })

    useUnmount(() => {
        if (!disableWrapper) document.body.style.overflow = 'auto'
    })

    const onModalClose = () => {
        setMounted(false)
        props?.onBeforeClose?.()

        setTimeout(() => {
            props?.close?.()
            props?.onClose?.()
        }, 500)
    }

    const renderModal = () => {
        if (!Modal) return

        return (
            <Modal
                {...rest}
                close={onModalClose}
            />
        )
    }

    return (
        <Wrapper
            className={mounted && 'mounted'}
            slideUp={props?.slideUp}
            slideRight={props?.slideRight}
            styles={styles}
            disableFade={props?.disableFade}
            disableWrapper={disableWrapper}
        >
            <OutsideClickHandler
                onOutsideClick={props.disableOutsideClick ? () => { return } : onModalClose}
            >
                {renderModal()}
            </OutsideClickHandler>
        </Wrapper>
    )
}

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	top: 0;
	height: 100vh;
	background: rgba(0,0,0, 0.25);
	z-index: 11;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.card {
		min-width: 500px;
		transform: scale(0.95);
		transition: ease transform 0.2s;
	}
	
	/* Animation */
	
	transition: opacity ease 0.35s;
    will-change: opacity;
    opacity: 0;

    &.mounted {
        opacity: 1;

        .card {
            transform: scale(1);
        }
    }

    /* Disable Fade */

    ${(props) => {
        if (props.disableFade) {
            return css`
                background: none;
            `
        }
    }}


   /* Disable Fade */

   ${(props) => {
        if (props.disableWrapper) {
            return css`
                position: fixed;
                top: auto;
                bottom: auto;
                left: auto;
                right: auto;
                height: auto;
            `
        }
    }}

    /* Slide up */

    ${(props) => {
        if (props.slideUp) {
            return css`
                ${slideUpAnim}
            `
        }
    }}

    /* Slide Right */

    ${(props) => {
        if (props.slideRight) {
            return css`
            	${slideRightAnim} 
            `
        }
    }}
`

// Animations

const slideUpAnim = css`
    justify-content: flex-end;
    transition: opacity ease 0.35s;
    will-change: transform;

    > * {
        transition: transform ease 0.45s;
        transform: translateY(200px);
        will-change: transform;
    }             

    &.mounted {
        > * {
            transform: translateY(0);
        }
    }  
`

const slideRightAnim = css`
    align-items: flex-end;
    transition: opacity ease 0.35s;

    > * {
        transition: transform ease 0.45s;
        transform: translateX(100%);
        will-change: transform;
    }             

    &.mounted {
        > * {
            transform: translateX(0);
        }
    }  
`

export default Modal
