import { 
    BlockText,
    BlockEmbed,
    BlockImageSingle,
} from './templates'
import Block from './templates/BlockTemplate';

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'image': BlockImageSingle,
        'embed': BlockEmbed,
        'bandcamp': BlockEmbed
    }
    
    return blocks[layout]
}


