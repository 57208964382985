import React from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { yellow, blue, red } from '../../styles/colors';
import Artist from '../Artist'

class Artists extends React.Component {

	state = {
		data: []
	}

	componentWillMount = () => {
		const data = JSON.parse(this.props.data.wordpressArtists.artists)
		
		this.setState({
			artists: data.artists,
			data: JSON.parse(this.props.data.wordpressArtistsPage.acf_json)
		})
	}

	componentDidMount = () => {
		const pathContext = this.props.pathContext;
		if (isClient()) {
			// window.history.pushState(null, null, '/artists');
		}
	}
	
	navigateToArtist = (slug) => {
		const { updateRoute } = this.props;
		
		updateRoute('right',
			<Artist 
				slug={slug} 
				artists={this.state.artists}
			/>
		)
	}

	renderArtists = () => {
		const { artists } = this.state;

		const items	= artists.map((artist, i) => {
			return (
				<ArtistItem
					key={i}
					onClick={() => this.navigateToArtist(artist.slug)}
				>
					<Heading>{artist.title}</Heading>
					<Image
						image={artist.acf.hero_image && artist.acf.hero_image.sizes.large}
					/>
				</ArtistItem>
			)
		})

		return (
			<Grid>
				{items}
			</Grid>
		)
	}
	
	render() {
		const { data } = this.state;

		return (
			<Container>
				{this.renderArtists()}
				<Donation>
					<Counter>{data.donation_counter}</Counter>
					<Content
						dangerouslySetInnerHTML={{__html: data.donation_text}}
					/>
				</Donation>
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	box-sizing: border-box;

	${media.phone`
		padding-top: 30px;
	`}
`

// Shared

const Heading = styled.div`
	flex: 1;
`

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
`

const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin-top: 45px;
	width: calc(100% + 32px);

	${media.phone`
		flex-direction: column;
		width: 100%;
		margin-top: 0;
	`}
`

const ArtistItem = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0 1 calc(50% - 32px);
	cursor: pointer;
	margin-bottom: 32px;

	${Image} {
		height: 13vw;
		max-height: 400px;
		min-height: 210px;
		mix-blend-mode: multiply;
		filter: grayscale(100%);

		${media.phone`
			height
		`}
	}

	${Heading} {
		font-family: 'Cooper';
		font-size: 32px;
		margin-bottom: 16px;

		${media.phone`
			font-size: 18px;
		`}
	}

	&:nth-child(odd) {
		margin-right: 32px;
	}

	&:hover {
		${Image} {
			mix-blend-mode: normal;
			filter: grayscale(0%);
		}
	}

	${media.phone`
		flex: 1 0 100%;

		&:nth-child(odd) {
			margin-right: 0;
		}

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	`}
`

// Donation

const Content = styled.div`
	font-family: 'Cooper';
	font-size: 30px;
	margin: 30px 0;
	line-height: 38px;

	p {
		margin-bottom: 28px;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`


const Donation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px 32px 0;
	width: 100%;
	transform: translateX(-32px);

	position: relative;

	${media.phone`
		padding: 48px 20px;
		transform: translateX(-20px);
	`}

`

const Counter = styled.div`
	font-family: 'Cooper';
	font-size: 30px;

	padding: 0 20px 3px;
	height: 45px;
	border: 3px solid black;
	border-radius: 12px;

	display: flex;
	justify-content: center;
	align-items: center;

	${media.phone`
		font-size: 18px;
		height: 32px;
		border: 2px solid black;
	`}
`

export const pageQuery = graphql`
	fragment artists on Query {
		wordpressArtists {
			artists 
		}
	}
`

Artists.background = yellow
Artists.indicator = blue
Artists.displayName = 'Artists'
Artists.template = 'artists'

export default Artists
