import React, { Fragment } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { forEach } from 'lodash'
import { Block } from '../../components'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import Post from '../Post/Post';
import { red, green, yellow, blue } from '../../styles/colors';

class Privacy extends React.Component {

	state = {
		data: null
	}

	componentWillMount = () => {
		const data = JSON.parse(this.props.data.wordpressPrivacy.acf_json)
		
		this.setState({
			data
		})
	}

	componentDidMount = () => {
		if (isClient() ) {
			window.history.pushState(null, null, '/about/privacy');
		}
	}
	

	render() {	
		const { data } = this.state;

		return (
			<Container>		
				<Heading>Privacy Policy</Heading>
				<Content
					dangerouslySetInnerHTML={{__html: data.description}}
				/>		
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	box-sizing: border-box;
	padding-top: 55px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.phone`
		padding-top: 30px;
	`}
`

const Title = styled.div``
const Description = styled.div``


// Shared

const Heading = styled.div`
	font-family: 'Cooper';
	font-size: 30px;
	margin-bottom: 32px;

	${media.phone`
		font-size: 18px;
	`}
`

const Content = styled.div`
	margin-bottom: 64px;

	p {
		margin-bottom: 28px;
		font-family: 'Cooper';
		font-size: 21px;
		line-height: 26px;
	}

	${media.phone`
		p {
			font-size: 18px;
			line-height: 24px;
		}
	`}
`

const StreamLink = styled.a`
	${hoverState}
	
	font-family: 'Cooper';
	font-size: 24px;

	padding: 0 20px;
	height: 48px;
	border: 2px solid black;
	border-radius: 12px;
	max-width: 300px;
	margin-top: 24px;
	margin-bottom: 80px;

	${hoverState}
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	${media.phone`
		font-size: 18px;
		height: 32px;
		border: 1px solid black;
		margin-top: 32px;
		margin-bottom: 64px;
	`}
`


const PreviousShows = styled.div`
	width: 100%;

	${Heading} {
		margin-bottom: 26px;
	}
`

const ShowLink = styled.a``

const Show = styled.a`
	font-family: 'Cooper';
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 48px;
	font-size: 21px;
	border-top: 2px solid black;
	padding: 10px 10px;
	${hoverState}

	${Title} {
		flex: 0 1 auto;
		font-family: 'Roboto Mono', monospace;
		font-size: 16px;
		text-transform: uppercase;
		margin-right: 32px;
		transform: translateY(-2px);
		max-width: 60%;
	}

	&:last-child {
		border-bottom: 2px solid black;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`

Privacy.background = blue
Privacy.indicator = green
Privacy.displayName = 'About'
Privacy.template = 'privacy'

export const pageQuery = graphql`
	fragment privacy on Query {
		wordpressPrivacy {
			acf_json 
		}
	}
`

export default Privacy
