export const animIn = (active, instant) => {    

    // console.log('animIn', active)

    // Allow cart to animate to 100vw on mobile

    const width = (window && window.innerWidth <= 1024) ? '100vw' : '50vw';

    let anim = {
        wrapper: {
            animation: {
                width: active ? width : '0vw',
            },
            duration: 750,
            begin: (element) => {
                element.map((elm, index)=>{
                    elm.style.opacity = 1  
                })            
            },
            easing: [0.37, 0.06, 0.11, 1.02],
        },
        content: {
            animation: {
                opacity: active ? 1 : 0,
            },
            delay: active ? 750 : 0,
            duration: active ? 750 : 0,
            easing: [0.25, 0.1, 0.25, 1]
        },
    }

    if (instant) {
        anim.wrapper.duration = 0;
        anim.content.delay = 0;
        anim.content.duration = 250;
    }

    return anim;

}


export const animOut = (active) => {

    // console.log('animOut', active)

    return {
        wrapper: {
            ...animIn(active).content,
            complete: (element) => {
                element.map((elm, index)=> {
                    elm.style.width = '0vw'  
                })            
            },
        },
        content: {
            ...animIn(active).content,
        }
    }
}

export const closeAll = (isMenu) => {

    console.log('closeAll', isMenu)

    return {
        wrapper: {
            animation: {
                width: '0vw',
            },
            duration: isMenu ? 375 : 200,
            delay: isMenu ? 100 : 0,
            begin: (element) => {
                element.map((elm, index)=>{
                    elm.style.opacity = 1  
                })            
            },
            easing: [0.37, 0.06, 0.11, 1.02],
        },
        content: {
            animation: {
                opacity: 0,
            },
            delay: isMenu ? 150 : 0,
            duration: 150,
            easing: [0.25, 0.1, 0.25, 1]
        }
    }
}
