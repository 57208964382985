import React from 'react'
import Helmet from 'react-helmet'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { find } from 'lodash'
import moment from 'moment'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { yellow, blue } from '../../styles/colors';
import { Block } from '../../components'
import Post from '../Post/Post';
import Artists from '../Artists/Artists';

class Artist extends React.Component {

	state = {
	
	}
	
	componentWillMount = () => {
		const { data } = this.props;
		const artists = JSON.parse(data.wordpressArtists.artists)
						
		this.setState({
			data: find(artists.artists, {slug: this.resolveSlug()})
		})
	}

	resolveSlug = () => {
		const { slug } = this.props;
		const pathContext = this.props.pathContext;

		if (slug) return slug;
		if (pathContext.slug) return pathContext.slug;
	}
	
	componentDidMount = () => {
		const { slug, artists } = this.props;
		
		if (isClient()) {
			window.history.pushState(null, null, `/artists/${this.resolveSlug()}`);
		}
	}

	navigateToNews = (slug) => {
		const { updateRoute } = this.props;
		
		updateRoute('left',
			<Post 
				slug={slug} 
			/>
		)
	}

	backToArtists = () => {
		const { updateRoute } = this.props;
		
		updateRoute('right',
			<Artists/>
		)
	}

	renderNews = () => {
		const { data } = this.state;

		if (!data.acf.related_news) return;

		const items	= data.acf.related_news.map((post, i) => {
			post = post.news_article

			return (
				<NewsItem
					key={i}
					onClick={() => this.navigateToNews(post.post_name)}
				>
					<Date>{moment(post.post_date).format('D MMM YY')}</Date>
					{post.post_title}
				</NewsItem>
			)
		})

		return (
			<News>
				<Heading>News</Heading>
				{items}
			</News>
		)
	}
	
	renderLinks = () => {
		const { data } = this.state;

		if (!data.acf.social_links) return;

		const items	= data.acf.social_links.map((link, i) => {
			return (
				<SocialLink
					key={i}
					target={'_blank'}
					href={link.link}
				>
					{link.name}
				</SocialLink>
			)
		})

		return (
			<Links>
				<Heading>Social</Heading>
				{items}
			</Links>
		)
    }
    
    renderBlocks = (blocks) => {
		if (!blocks) return;

		return blocks.map((block, i) => {  
			console.log(block)
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					{...block}
				/>
			)
		})
	}

	render() {
		const { data } = this.state;
		
		return (
			<Container>
				<Helmet>
					<title>{data.title} — Music in Exile</title>
					<meta name="description" content={data.acf.seo_description}/>
				</Helmet>

				<Top>
					<Heading>{data.title}</Heading>
					<Location>{data.acf.location}</Location>
				</Top>

				<Image
					image={data.acf.hero_image && data.acf.hero_image.sizes.medium2}
				/>

                {this.renderBlocks(data.acf.blocks)}

				<FooterImage
					image={data.acf.footer_image && data.acf.footer_image.sizes.medium2}
				/>

				{this.renderNews()}
				{this.renderLinks()}

				<Back
					onClick={this.backToArtists}
				>
					Back to Artists
				</Back>
				
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	padding-top: 29px;

	${media.phone`
		padding-top: 20px;
	`}
`

// Shared

const Heading = styled.div`
	font-family: 'Cooper';
	font-size: 30px;

	${media.phone`
		font-size: 18px;
	`}
`

const Description = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
	min-height: 420px;
	height: 30vw;
	width: calc(100% + 64px);
	transform: translateX(-32px);
	mix-blend-mode: multiply;
	filter: grayscale(100%);

	&:hover {
		mix-blend-mode: normal;
		filter: grayscale(0%);
	}
`


const FooterImage = styled(Image)`
	margin-top: 48px;
`

// Top

const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 22px;
`

const Location = styled.div`
	font-family: 'Cooper';
	font-size: 30px;

	padding: 0 20px;
	height: 45px;
	border: 3px solid black;
	border-radius: 12px;

	display: flex;
	justify-content: center;
	align-items: center;

	${media.phone`
		font-size: 18px;
		height: 32px;
		border: 2px solid black;
	`}
`

// Content

const Content = styled.div`
	font-family: 'Cooper';
	font-size: 21px;
	margin: 30px 0;
	line-height: 26px;

	p {
		margin-bottom: 28px;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`

// News

const News = styled.div`
	margin-top: 40px;

	${Heading} {
		margin-bottom: 26px;
	}
`

const Date = styled.div`
	font-family: 'Roboto Mono', monospace;
	font-size: 16px;
	text-transform: uppercase;
	margin-right: 32px;
`

const NewsItem = styled.div`
	font-family: 'Cooper';
	display: flex;
	align-items: center;
	height: 48px;
	font-size: 21px;
	border-top: 3px solid black;
	${hoverState}
	cursor: pointer;

	&:last-child {
		border-bottom: 3px solid black;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`

const Back = styled.div`
	font-family: 'Cooper';
	font-size: 21px;
	margin-top: 42px;
	text-decoration: underline;
	${hoverState}
	cursor: pointer;

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`

// Link

const SocialLink = styled.a`
	font-family: 'Cooper';
	display: flex;
	align-items: center;
	height: 48px;
	font-size: 21px;
	border-top: 3px solid black;
`

const Links = styled.div`
	margin-top: 40px;

	${Heading} {
		margin-bottom: 26px;
	}
`


Artist.displayName = 'Artists'
Artist.background = yellow
Artist.indicator = blue
Artist.template = 'artist'

export default Artist
