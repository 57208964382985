import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { Link, navigate } from 'gatsby'

import { Modal } from '../../components'
import DonationModal from '../../modals/Donation'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { pink, red } from '../../styles/colors';
import { useMount, useSearchParam } from 'react-use'

const About = (props) => {
	const [data, setData] = useState(false)
	const modalManager = Modal.useModals()
	const showDonation = useSearchParam('showDonation');

	useMount(() => {
		const data = JSON.parse(props.data.wordpressAbout.acf_json)
		setData(data)

		if (isClient()) {
			window.history.pushState(null, null, '/about');

			if (showDonation == 'true') {
				openDonationModal()
			}
		}
	})

	const openDonationModal = () => {
		modalManager.open(modal => (
            <Modal 
                modal={DonationModal}
                {...modal}
            />
        ))
	}

	const renderLinks = () => {
		if (!data.social_links) return;

		const items	= data.social_links.map((link, i) => {
			return (
				<Fragment>
					<SocialLink
						key={i}
						target={'_blank'}
						href={link.link}
					>
						{link.name}
					</SocialLink>

					{i < (data.social_links.length - 1) && '|'}
				</Fragment>
			)
		})

		return (
			<Links>
				{items}
			</Links>
		)
    }
	
	
	return (
		<Container>
			<Connect>
				<Donation
					onClick={openDonationModal}
				> 
					Donate now
				</Donation>
				
				<Subscribe
					href={'https://bedroomsuckrecords.us7.list-manage.com/subscribe?u=97c89751baf3f667c34263ff2&id=f34cf80238'}
					target={'_blank'}
				>
					Subscribe to the newsletter
				</Subscribe>

				{renderLinks()}
			</Connect>


			<Content
				dangerouslySetInnerHTML={{__html: data.content}}
			/>

			{data.footer_image && (
				<Image
					image={data.footer_image && data.footer_image.sizes.medium2}
				/>
			)}

		</Container>
	)
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};
	box-sizing: border-box;
	padding-top: 16px;

	${media.phone`
		padding-top: 0;
	`}
`

const Content = styled.div`
	font-family: 'Cooper';
	font-size: 30px;
	margin: 30px 0;
	line-height: 38px;

	p {
		margin-bottom: 28px;
	}

	${media.phone`
		font-size: 18px;
		line-height: 24px;
	`}
`

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage}
	min-height: 420px;
	height: 30vw;
	width: calc(100% + 64px);
	transform: translateX(-32px);
	mix-blend-mode: multiply;
	filter: grayscale(100%);
`

// Social Links

const SocialLink = styled.a`
	font-family: 'Cooper';
	display: flex;
	align-items: center;
	font-size: 21px;

	&:not(:first-child) {
		margin-left: 10px;
	}

	&:not(:last-child) {
		margin-right: 10px;
	}
`

const Links = styled.div`
	display: flex;
	align-items: center;
	margin-top: 24px;

	${media.phone`
		position: initial;
		font-size: 12px;
		margin-top: 32px;
	`}
`

const Subscribe = styled.a`
	${hoverState}
	
	font-family: 'Cooper';
	font-size: 24px;

	padding: 0 20px;
	height: 48px;
	border: 2px solid black;
	border-radius: 12px;
	max-width: 300px;
	margin-top: 24px;

	${hoverState}
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	${media.phone`
		font-size: 18px;
		height: 32px;
		border: 1px solid black;
		margin-top: 32px;
	`}
`

const Connect = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 96px;

	${media.phone`
		margin-bottom: 0;
	`}
`

const Donation = styled(Subscribe)`
	margin-top: 80px;
`

export const pageQuery = graphql`
	fragment about on Query {
		wordpressAbout {
			acf_json 
		}
	}
`

About.background = pink
About.indicator = red
About.displayName = 'About'
About.template = 'about'

export default About
