import { 
    BlockText,
    BlockEmbed,
    BlockImage,
    BlockPullQuote,
    BlockHeading,
    BlockReleaseLinks,
    BlockVideo,
    BlockDivider,
    BlockLinkGrid
} from './templates'
import Block from './templates/BlockTemplate';

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'image': BlockImage,
        'pull_quote': BlockPullQuote,
        'heading': BlockHeading,
        'release_links': BlockReleaseLinks,
        'video': BlockVideo,
        'divider': BlockDivider,
        'image_link_grid': BlockLinkGrid,
        'embed': BlockEmbed,
        'bandcamp': BlockEmbed,
    }
    
    return blocks[layout]
}


