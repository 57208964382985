import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import { media } from '../../../styles/utils'
import { container, bgIcon, bgImage } from '../../../styles/global'

const BlockImage = (props) => {
    const { image, second_image, image_layout } = props;

    console.log(props)

    const renderImages = () => {
        const images = [image, image_layout == 'double' && second_image];

        return images.map((item, i) => {
            if (!item || !item.sizes) return;
            return (
                <Image
                    key={item.id}
                    src={item.sizes.medium2}
                >
                    {(src, loading) => {
                        return (
                            <BGImage 
                                image={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image> 
            )
        })
    }
    
        
	return (
		<Wrapper
            layout={image_layout}
        >
            {renderImages()}
		</Wrapper>
	)
}

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    /* Layouts */ 

    ${props => {
        if (props.layout == 'full-width') return css`
            ${BGImage} {
                padding-bottom: 60.65%;
            }
        ` 

        if (props.layout == 'portrait') return css`
            ${BGImage} {
                width: calc(50% - 10px);
                padding-bottom: 60.65%;

                ${media.tablet`
                    width: 100%;
                    padding-bottom: 120.65%;
                `}
            }
        `


        if (props.layout == 'double') return css`
            ${media.tablet`
                flex-direction: column;
            `}

            ${BGImage} {
                width: calc(50% - 10px);
                padding-bottom: 30.65%;

                ${media.tablet`
                    width: 100%;
                    padding-bottom: 120.65%;

                    &:not(:last-child) {
                        margin-bottom: 30px;
                    }
                `}
            }
        `

        if (props.layout == 'logo') return css`
            ${BGImage} {
                width: 450px;
                height: 290px;
                ${bgIcon};
                margin: 0 auto;
            }
        ` 
    }}
`


BlockImage.wrapper = css`

`

export default BlockImage
