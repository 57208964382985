import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'


class BlockImageSingle extends React.Component {

	state = {
	
	}
	
	render() {
        const { image } = this.props;
        
		return (
            <Container>

                <Image
                    key={image.id}
                    src={image.sizes.medium2}
                >
                    {(src, loading) => {
                        return (
                            <BGImage 
                                image={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image> 

            </Container>
		)
	}
}

const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${container}
    width: calc(100% + 64px);
	transform: translateX(-32px);
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
    width: 100%;
    height: 420px;

`

BlockImageSingle.wrapper = css`

`

export default BlockImageSingle
