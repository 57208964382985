import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgImage } from '../../../styles/global'

const BlockDivider = (props) => {
	const { paragraph } = props;
        
	return (
		<Wrapper/>
	)
}

// Layout

const Wrapper = styled.div`
	width: 100%;
	border-top: 2px solid black;
`

BlockDivider.wrapper = css`

`

export default BlockDivider
