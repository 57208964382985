import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils';

class Block extends React.Component {

	state = {
	
	}
	
	render() {
		const { layout, lastBlock, block_type, inAccordion, display_block} = this.props;
        const BlockLayout = resolveBlock(layout);
		//if (!BlockLayout || !display_block) return <></>

		return (
			<Wrapper
				className={[`block_${layout}`, layout]}
				layout={layout}
				blockType={block_type}
				blockProps={this.props}
				styles={BlockLayout && BlockLayout.wrapper}
				lastBlock={lastBlock}
				inAccordion={inAccordion}
			>
				{BlockLayout && (
					<BlockLayout {...this.props}/>
				)}
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
    width: 100%;
	margin-top: 24px;
	margin-bottom: 24px;

	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}
`

export default Block
