import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const BlockHeading = (props) => {
	const { content } = props;
        
	return (
		<Wrapper>
			<Heading
				dangerouslySetInnerHTML={{__html: content}}  
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
  	display: flex;
	justify-content: center;
`

const Heading = styled.div`
	font-family: Helvetica, Arial, sans-serif;
	font-size: 24px;
	line-height: 28px;
	font-weight: bold;
	text-transform: uppercase;

	${media.tablet`
		font-size: 18px;
		line-height: 21px;
	`}
`

BlockHeading.wrapper = css`
	margin-top: -30px;

	${media.tablet`
		margin-top: 0;
	`}
`

export default BlockHeading
