import React from 'react'
import styled, { css } from 'styled-components'
import { VelocityComponent } from 'velocity-react'
import { Link, navigate } from 'gatsby'

import music from '../../assets/images/music.svg'
import exile from '../../assets/images/exile.svg'
import musicWhite from '../../assets/images/music-white.svg'
import exileYellow from '../../assets/images/exile-yellow.svg'

import { media, width } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import News from '../../modules/News/News';
import { isCurrentRoute } from './utils';
import { blue } from '../../styles/colors';

class Hemisphere extends React.Component {

	constructor(props) {
		super(props);
		this.scroll = React.createRef();
	}

	state = {
	
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.currentRoute.type.template !== this.props.currentRoute.type.template) {
			this.scroll.current.scrollTop = 0;
		}
	}

	renderNav = () => {
		const { navItems, type, updateRoute, currentRoute } = this.props;

		const items = navItems.map((item, i) => {
			if (item.hide) return;

			if (item.component) {
				return (
					<NavItem
						key={i}
						onClick={() => updateRoute(type, item.component)}
						active={isCurrentRoute(currentRoute, item.component)}
						colour={currentRoute.type.indicator}
					>
						<Indicator/>
						<Label>
							{item.name}
						</Label>
					</NavItem>
				)
			} 

			if (item.link) {
				return (
					<NavItemLink
						key={i}
						href={item.link}
						target={'_blank'}
					>
						<Indicator/>
						<Label>
							{item.name}
						</Label>
					</NavItemLink>
				)
			}

		
		})

		return (
			<Nav>
				{items}
			</Nav>
		)
	}

	resolveHeaderImage = (type) => {
		const { background } = this.props;

		const options = {
			'left': {
				'#FDBAA0': music,
				'#F15A48': musicWhite,
				'#FFDE02': music,
				'#0182C5': musicWhite,
			},
			'right': {
				'#FFDE02': exile,
				'#0182C5': exileYellow,
				'#FDBAA0': exile,
				'#F15A48': exileYellow,
			}
		}

		return options[type][background]
	}

	renderHeader = (type) => {
		return (
			<Header>
				<Logo
					src={this.resolveHeaderImage(type)}
				/>
				
				{width.tablet() && (
					<Logo
						src={this.resolveHeaderImage('right')}
					/>
				)}
				{this.renderNav()}
			</Header>
		)
	}
	
	render() {
		const { background, type, currentRoute, data, updateRoute, pathContext } = this.props;

		return (
			<Container
				background={background}
				type={type}
			>
				{this.renderHeader(type)}

				<Scroll
					ref={this.scroll} 
				>
					{React.cloneElement(
						currentRoute,
						{ 
							data: data,
							updateRoute: updateRoute,
							pathContext: pathContext
						}
					)}
				</Scroll>
			</Container>
		)
	}
}


// Hemispheres

const Container = styled.div`
	flex: 1 0 50%;
	background-color: ${props => props.background};

	box-sizing: border-box;
	display: flex;
    flex-direction: column;

	${props => {
		if (props.type == 'right') return css`
			${media.tablet`
				display: none;
			`}
		`
	}}
`

const Scroll = styled.div`
	overflow-y: scroll;
	height: 100%;
	padding: 0 32px;
	padding-bottom: 64px;

	${media.phone`
		padding: 0 20px;
		padding-bottom: 48px;
	`}
`

const Header = styled.div`
	padding: 48px 32px 0;

	${media.phone`
		padding: 32px 20px 0;
	`}
`

const Logo = styled.img`
	width: 100%;
	height: auto;

	&:nth-child(2) {
		display: none;
		margin-top: 16px;

		${media.tablet`
			display: flex;
		`}
	}
`

// Shared

const Label = styled.div``

// Nav

const Nav = styled.div`
	display: flex;
	margin-top: 45px;
	padding-bottom: 18px;
	border-bottom: 2px solid black;

	${media.phone`
		margin-top: 24px;
		flex-flow: row wrap;
	`}
`

const Indicator = styled.div`
	height: 18px;
	width: 18px;
	border: 3px solid black;
	border-radius: 50%;
	margin-right: 8px;

	${media.phone`
		height: 10px;
		width: 10px;
		margin-right: 4px;
		border: 2px solid black;
	`}
`


const navItem = css`
	display: flex;
	align-items: center;
	font-family: 'Cooper';
	font-size: 32px;
	cursor: pointer;
	user-select: none;

	&:not(:last-child) {
		margin-right: 24px;
	}

	${media.phone`
		font-size: 18px;
		margin-right: 8px;
		margin-bottom: 10px;

		&:not(:last-child) {
			margin-right: 16px;
		}
	`}

	/* Active */

	${props => {
		if (props.active) return css`
			${Indicator} {
				background: ${props.colour};
			}
		`
	}}
`

const NavItem = styled.div`
	${navItem}
`
const NavItemLink = styled.a`
	${navItem}
`

export default Hemisphere

