import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'


class BlockText extends React.Component {

	state = {
	
	}
	
	render() {
        const { content } = this.props;
        
		return (
            <Container>
				<Text
					dangerouslySetInnerHTML={{__html: content}}  
				/>
			</Container>
		)
	}
}


const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${padding};
	${container}
`

const Text = styled.div`
	font-family: 'Cooper';
	font-size: 20px;
	line-height: 24px;

	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}
`

BlockText.wrapper = css`
	display: flex;
	justify-content: center;
`

export default BlockText
