import React from 'react'

import { 
    Artist,
    Artists,
    About,
    News,
    Post,
    Releases,
    Music,
    Radio,
    Privacy
} from './modules'

export const resolveComponent = (name) => {
    // Map layout strings to blocks

    const components = {
        'artists': <Artists/>,
        'artist': <Artist/>,
        'about': <About/>,
        'news': <News/>,
        'post': <Post/>,
        'releases': <Releases/>,
        'music': <Music/>,
        'radio': <Radio/>,
        'privacy': <Privacy/>,
    }
    
    return components[name]
}

export const getCartQuantity = (cart) => {
    let quantity = 0;

    if (cart && cart.length > 0) {
        cart.forEach((elm, i) => {
            const cartItem = cart[i];
            quantity = quantity + cartItem.quantity
        });
    }

    return quantity
}

export const getCartTotal = (cart) => {
    let total = 0;

    if (cart && cart.length > 0) {
        cart.forEach((elm, i) => {
            const cartItem = cart[i];
            total = total + cartItem.quantity * cartItem.total
        });
    }

    return total
}

// Facebook Track

export const fbTrack = (a, b, c) => {
    if (typeof fbq === `function`) {
        fbq(a, b, c);
    }
}